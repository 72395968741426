import { MainContext } from '../../contex';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useState } from 'react';

import Monitor from '../Monitor';
import logo from '../../images/logo.png';
import frame1 from '../../images/629c80075b28153566ae03a7_Frame.svg';
import frame2 from '../../images/629c80075b2815330cae03a8_Frame-1.svg';
import frame3 from '../../images/629c800707d54f90f812b873_Frame-2.svg';
import bottom from '../../images/629c7ef3203ede81da50094e_bottom.svg';

function App() {

    const [address, setAddress] = useState('0x0');
    const [smalladdr, setSmallAddr] = useState('');
    const [web3, setWeb3] = useState(null);
    const [provider, setProvider] = useState(null);
    const [connected, setConnected] = useState(false);
    const [blockdiff, setBlockDiff] = useState(0);
    const [packageId, setPackageId] = useState(0);
    const seconds = 7200;
   
    /**
     * BSC
     */
    const token = "0x55d398326f99059ff775485246999027b3197955";
    const beeBlock = "0x019D12099181D29aBdE1AC6E9c58D8ED530443d9";

    /**
     * GANACHE
     */
    //const token = "0x0dfE73A3259362b674060c6De6730362F5AC3DCC";
    //const beeBlock = "0xAEB74f0AdaCBd62d34b4F417f62402Cf3D56Ad55";

    const data = {
        address, setAddress,
        smalladdr, setSmallAddr,
        web3, setWeb3,
        provider, setProvider,
        connected, setConnected,
        token, beeBlock,
        blockdiff, setBlockDiff,
        packageId, setPackageId,
        seconds
    }

    return (
        <MainContext.Provider value={data}>
            <Router>
                <Switch>
                    <Route exact path={["/", "/home"]}>
                        <div>
                            <div className="section header-section wf-section">
                                <div className="container header-container">
                                    <a href="/#" className="w-inline-block">
                                        <img src={logo} loading="lazy" alt="Bee Block Logo" width="200"/>
                                    </a>
                                </div>
                            </div>
                            <div className="section hero-section wf-section ptb-5">
                                <div className="container">
                                    <div className="rows hero">
                                        <div className="full-width v-group gap-24">
                                            <h1 className="heading">Join World’s First Crypto Apiary</h1>
                                            <p className="paragraph-3" >
                                                Start earning up to $30 per day
                                                <br/>be a Beekeeper!
                                            </p>
                                        </div>
                                        <div className="full-width center">
                                            <img src="./images/629c7c044d678406c47dcd38_Frame.png" alt="" className="image-2"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Monitor />
                            
                            
                            <div className="section white-bg wf-section">
                                <div className="container">
                                    <div className="rows hero">
                                        <div className="full-width v-group gap-24 center">
                                            <img src={frame1} loading="lazy" alt="" className="w64"/>
                                            <h3 className="center">Anti-Whale</h3>
                                            <p className="paragraph">Bee&#x27;n&#x27;Bee has effective<br/>anti-dumping measures</p>
                                        </div>
                                        <div className="full-width v-group gap-24 center">
                                            <img src={frame2} loading="lazy" alt="" className="w64"/>
                                            <h3 className="center">Extremely Sustainable</h3>
                                            <p className="paragraph">The reward system is sustained<br/>by the contract</p>
                                        </div>
                                        <div className="full-width v-group gap-24 center">
                                            <img src={frame3} loading="lazy" alt="" className="w64"/>
                                            <h3 className="center">Highly Secure</h3>
                                            <p className="paragraph">Our contract is audited and can’t be changed over time</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <img src={bottom} loading="lazy" alt="" className="image-3"/>

                            <div id="about" className="section hero-section wf-section">
                                <div className="container">
                                    <div className="rows hero reverse">
                                        <div className="full-width center">
                                            <img src="./images/629c9d465b281598ffae9762_Honey.png" alt="" className="image-2"/>
                                        </div>
                                        <div className="full-width v-group gap-24">
                                            <h1 className="heading">About BeeBlock</h1>
                                            <p className="paragraph-3">BeeBlock is the most advanced and fully sustainable ecosystem that rewards its investors daily. As the bees make honey, the beekeepers reward you.</p>
                                            <div className="h-group gap-12">
                                                <div className="tag grow">
                                                    <div className="text-block-3">$6.25</div>
                                                    <div className="text-block-4">Block Reward 1</div>
                                                </div>
                                                <div className="tag grow">
                                                    <div className="text-block-3">$10.00</div>
                                                    <div className="text-block-4">Block Reward 2</div>
                                                </div>
                                                <div className="tag grow">
                                                    <div className="text-block-3">$13.75</div>
                                                    <div className="text-block-4">Block Reward 3</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section hero-section wf-section">
                                <div className="container">
                                    <div className="rows hero">
                                        <div className="full-width v-group gap-24">
                                            <h1 className="heading">What makes BeeBlock special?</h1>
                                            <p className="paragraph-3">As an investor, you main ask yourself a legitimate question, how can the BeeBlock deliver such high returns while being sustainable? The protocol uses advanced anti-whale and anti-dumping mechanisms to secure users' funds.</p>
                                            <div className="h-group gap-12">
                                                {/* <a target="_blank" href="/" className="button white-bg">
                                                    <div className="button-text active">Read more</div>
                                                    <img src="./images/628b514b5e7502a067c350b9_close_icon.svg" loading="lazy" alt="Disconnect icon" className="disconnect-icon hidden"/>
                                                </a> */}
                                            </div>
                                        </div>
                                        <div className="full-width center">
                                            <img src="./images/629ca1fc88674200b28150d6_Honeycombs.png" loading="lazy" data-w-id="c339d3fa-1422-31d0-b29d-c9026c20ec40" sizes="(max-width: 479px) 52vw, (max-width: 767px) 47vw, (max-width: 991px) 48vw, 46vw" srcSet="./images/629ca1fc88674200b28150d6_Honeycombs-p-500.png 500w, ./images/629ca1fc88674200b28150d6_Honeycombs.png 720w" alt="" className="image-2"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img src="./images/629c7ed5b61709081d12ec34_top.svg" loading="lazy" alt="" className="image-3"/>
                            <div id="roadmap" className="section white-bg wf-section">
                                <h2 className="bottom-40">Roadmap</h2>
                                <div className="container">
                                    <div className="rows her mb-30">
                                        <div className="full-width v-group gap-24 center box">
                                            <h3 className="center">2023 Q2</h3>
                                            <p className="paragraph align-right full-width">
                                                - Idea development<br/>
                                                - Design identity creation<br/>
                                                - Contract Development<br/>
                                                - Website Development<br/>
                                                - Contract Deployment and Audit<br/>
                                                - BeeBlock dApp launch<br/>
                                                - Marketing campaign<br/>
                                                - Update website interface<br/>
                                            </p>
                                        </div>
                                        <div className="full-width v-group gap-24 center box">
                                            <h3 className="center">2023 Q3</h3>
                                            <p className="paragraph align-right full-width">
                                                - BeeBlock dApp interface update<br/>
                                                - BeeBlock new hive<br/>
                                                - Add more information in Docs<br/>
                                            </p>
                                        </div>
                                        <div className="full-width v-group gap-24 center box">
                                            <h3 className="center">2023 Q4</h3>
                                            <p className="paragraph align-right full-width">
                                                - BeeBlock hive (BNB )<br/>
                                                - More unique features<br/>
                                                - Marketing campaign (continuing)<br/>
                                                - Creation of token contract<br/>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="rows hero">
                                        <div className="full-width v-group gap-24 center box">
                                            <h3 className="center">2024 Q1</h3>
                                            <p className="paragraph align-right full-width">
                                            - Tokenomics release <br/>
                                            - KYC&AUDIT<br/>
                                            - BeeB Pinksale launch<br/>
                                            - Huge marketing<br/>
                                            - CMC&CG Listing<br/>
                                            - BeeBlock hive (BeeB)<br/>
                                            </p>
                                        </div>
                                        <div className="full-width v-group gap-24 center box">
                                            <h3 className="center">2024 Q2</h3>
                                            <p className="paragraph align-right full-width">
                                            - MEXC Listing<br/>
                                            - China marketing<br/>
                                            - AMA in local groups<br/>
                                            </p>
                                        </div>
                                        <div className="full-width v-group gap-24 center box">
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img src="./images/629c7ef3203ede81da50094e_bottom.svg" loading="lazy" alt="" className="image-3"/>
                            <div className="section hero-section wf-section">
                                <div className="container">
                                    <div className="rows hero center">
                                
                                    </div>
                                </div>
                            </div>
                            <div className="section hero-section wf-section">
                                <div className="container">
                                    <div className="rows hero center ptb-5">
                                        <img src={logo} loading="lazy" alt="Bee Block Logo" width="200"/>
                                    </div>
                                    <div className="rows hero center">
                                        <p className="paragraph-4">© Copyright BeeBlock 2022. All rights reserved.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Route>
                </Switch>
            </Router>
        </MainContext.Provider>
    );
  }
  
  export default App;
  