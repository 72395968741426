import { MainContext, useContext } from "../../contex";

const Hex = (props) =>  {
    
    const { packageId } = useContext(MainContext);
    return(
        <div className="left-hex" onClick={() => props.click(props.num)} title={packageId.concat("/").concat(props.num * 64)}>
            <div className={(packageId / 64 >= props.num) ? "top top-level3":(packageId / 16 >= props.num) ? "top top-level2":(packageId / 4 >= props.num) ? "top top-level1":"top"}></div>
            
            <div className={(packageId / 64 >= props.num) ? "middle mid-level3":(packageId / 16 >= props.num) ? "middle mid-level2":(packageId / 4 >= props.num) ? "middle mid-level1":"middle"}><span>{(Math.floor((packageId - props.num) / props.num) > 64) ? '64': Math.floor((packageId - props.num) / props.num)} / 64 <br/><small>{props.num}</small></span></div>
            
            <div className={(packageId / 64 >= props.num) ? "bottom bot-level3":(packageId / 16 >= props.num) ? "bottom bot-level2":(packageId / 4 >= props.num) ? "bottom bot-level1":"bottom"}></div>
            {/* <p>{(packageId / 4)} {packageId}</p>
            <p>{(packageId / 16)} {packageId}</p>
            <p>{(packageId / 64)} {packageId}</p> */}
        </div>
    );
}

export default Hex;