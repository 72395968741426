import { MainContext, useContext } from "../../contex";
import { useState, useEffect } from 'react';
import Swal from "sweetalert2";
import close from '../../images/close.svg';

const Contract = require('web3-eth-contract');


const Detail = (props) => {

    const { connected, address, provider, beeBlock, packageId } = useContext(MainContext);
    Contract.setProvider(provider);

    const [nonce, setNonce] = useState(0);
    const [levels, setLevels] = useState([]);
    
    // Package ID
    useEffect(() => {
        const fetchData = async () => {
            if(connected, props.id){
                const ABI = [{
                    "inputs": [
                      {
                        "internalType": "uint256",
                        "name": "_id",
                        "type": "uint256"
                      }
                    ],
                    "name": "getPackages",
                    "outputs": [
                      {
                        "internalType": "address",
                        "name": "user",
                        "type": "address"
                      },
                      {
                        "internalType": "uint256[]",
                        "name": "levels",
                        "type": "uint256[]"
                      }
                    ],
                    "stateMutability": "view",
                    "type": "function",
                    "constant": true
                  }];
      
                let _contract = new Contract(ABI, beeBlock);
                
                let _package = await _contract.methods.getPackages(props.id).call({from:address});
                // console.log('package :',_package);
                setLevels(_package.levels);
                
            }
        }
        fetchData();
    }, [connected, address, nonce, props.id]);

    const Withdraw = async (_id) => {
        console.log('ID',_id, props.id);

        const ABI = [{
            "inputs": [
              {
                "internalType": "uint256",
                "name": "_userPackageId",
                "type": "uint256"
              },
              {
                "internalType": "uint8",
                "name": "level",
                "type": "uint8"
              }
            ],
            "name": "withDraw",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
          }];
      
        let _contract = new Contract(ABI, beeBlock);
      
        try {
            _contract.methods.withDraw(props.id,_id).send({
                from: address,
                value: 0,
            }).on('transactionHash', function (hash) {
                Swal.fire({
                    title: 'Withdraw is Started',
                    text: 'Your Withdraw hash is '.concat(hash).concat('. please wait until the transaction is completed'),
                    icon: 'success',
                    allowOutsideClick: false,
                    showConfirmButton: false
                });
            })
            .on('receipt', function (receipt) {
                setNonce(nonce + 1);
                Swal.fire({
                    title: 'Withdraw Confirmation',
                    text: 'Confirmed Your Withdraw',
                    icon: 'success'
                });
            })
            .on('error', function (error, receipt) {
                Swal.fire({
                    title: 'Withdraw Failed',
                    icon: 'error'
                })
            });
        } catch (err) {
            Swal.fire({
                title: 'User Canceled',
                icon: 'error'
            })
            // console.log(err);
        }

    }

    return(
        (props.id != 0) ?
        <div className='detail'>
            <div className="hex-row2 even">
                <div className="hex"><div className="top"></div><div className="middle"><span>{props.id}</span></div><div className="bottom"></div></div>
                <div className="hex"><div className="top"></div>
                <div className="middle" onClick={props.detayClose}>
                    <img src={close} alt="close" />
                </div>
                <div className="bottom"></div></div>
            </div>
            <div className="hex-row2">
                {
                    (levels[1] !== '0') ? 
                    <div className="hex lh0" onClick={() => Withdraw(1)}>
                        <div className={(packageId / 4 >= props.id) ? "top top2-level1":"top"}></div>
                        <div className={(packageId / 4 >= props.id) ? "middle mid2-level1":"middle"}>
                            <span>{(Math.floor(packageId / props.id) > 4) ? '4': Math.floor(packageId / props.id)} / 4</span>
                            <span className="fs10 db">
                                { ( packageId < (props.id * 4) ) ? (packageId + " / " + (props.id * 4)) : ((props.id * 4) + "/" + (props.id * 4)) }
                            </span>
                        </div>
                        <div className={(packageId / 4 >= props.id) ? "bottom bot2-level1":"bottom"}></div>
                    </div> : 
                    <div className="hex"><div className="top"></div><div className="middle"></div><div className="bottom"></div></div>
                }
                {
                    (levels[2] !== '0') ?
                    <div className="hex lh0" onClick={() => Withdraw(2)}>
                        <div className={(packageId / 16 >= props.id) ? "top top2-level2":"top"}></div>
                        <div className={(packageId / 16 >= props.id) ? "middle mid2-level2":"middle"}>
                            <span>{(Math.floor(packageId / props.id) > 16) ? '16': Math.floor(packageId / props.id)} / 16 </span>
                            <span className="fs10 db">
                                { ( packageId < (props.id * 16) ) ? (packageId + " / " + (props.id * 16)) : ((props.id * 16) + "/" + (props.id * 16)) }
                            </span>
                        </div>
                        <div className={(packageId / 16 >= props.id) ? "bottom bot2-level2":"bottom"}></div>
                    </div> : 
                    <div className="hex"><div className="top"></div><div className="middle"></div><div className="bottom"></div></div>
                }
                {
                    (levels[3] !== '0') ?
                    <div className="hex lh0" onClick={() => Withdraw(3)}>
                        <div className={(packageId / 64 >= props.id) ? "top top2-level3":"top"}></div>
                        <div className={(packageId / 64 >= props.id) ? "middle mid2-level3":"middle"}>
                            <span>{(Math.floor(packageId / props.id) > 64) ? '64': Math.floor(packageId / props.id)} / 64 </span>
                            <span className="fs10 db">
                                { ( packageId < (props.id * 64) ) ? (packageId + " / " + (props.id * 64)) : ((props.id * 64) + "/" + (props.id * 64)) }
                            </span>
                        </div>
                        <div className={(packageId / 64 >= props.id) ? "bottom bot2-level3":"bottom"}></div>
                    </div> : 
                    <div className="hex"><div className="top"></div><div className="middle"></div><div className="bottom"></div></div>
                }
                
            </div>
            <div className="hex-row2 even">
                <div className="hex"><div className="top"></div><div className="middle"></div><div className="bottom"></div></div>
                <div className="hex"><div className="top"></div><div className="middle"></div><div className="bottom"></div></div>
            </div>
        </div> : ''
    )
}

export default Detail;