import { MainContext, useContext } from "../../contex";
import { useEffect } from "react";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

const Nav = () => {
  const {
    connected,
    setConnected,
    setSmallAddr,
    address,
    setAddress,
    setWeb3,
    setProvider,
  } = useContext(MainContext);

  const Metamask = window.Metamask;

  useEffect(() => {
    if (connected) {
      let smalladdr = address
        .substring(0, 5)
        .concat(".....")
        .concat(address.substring(address.length - 5));
      setSmallAddr(smalladdr);
    }
  }, [connected, address, setSmallAddr]);

  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        rpc: {
          56: "https://bsc-dataseed1.binance.org",
        },
        chainId: 56,
      },
    },
    metamask: {
      package: Metamask,
      options: {
        rpc: {
          56: "https://bsc-dataseed1.binance.org",
        },
        chainId: 56,
      },
    },
  };

  let web3Modal = new Web3Modal({
    network: "binance",
    cacheProvider: true,
    providerOptions,
    disableInjectedProvider: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      if (web3Modal.cachedProvider && !connected) {
        try {
          const provider = await web3Modal.connect();
          // regular web3 provider methods
          const newWeb3 = new Web3(provider);
          const accounts = await newWeb3.eth.getAccounts();

          // Subscribe to provider disconnection

          provider.on("accountsChanged", (accounts) => {
            setAddress(accounts[0]);
          });

          // Subscribe to provider disconnection
          provider.on("disconnect", (error) => {
            web3Modal.clearCachedProvider();
            setConnected(false);
            setAddress("0x0");
          });

          setProvider(provider);
          setWeb3(newWeb3);
          setAddress(accounts[0]);
          setConnected(true);
        } catch (err) {
          // console.log("User Closed");
        }
      }
    };
    fetchData();
  });

  const onConnect = async () => {
    try {
      const provider = await web3Modal.connect();
      // regular web3 provider methods
      const newWeb3 = new Web3(provider);
      const accounts = await newWeb3.eth.getAccounts();

      // Subscribe to provider disconnection

      provider.on("accountsChanged", (accounts) => {
        setAddress(accounts[0]);
      });

      // Subscribe to provider disconnection
      provider.on("disconnect", (error) => {
        Disconnect();
      });

      setProvider(provider);
      setWeb3(newWeb3);
      setAddress(accounts[0]);
      setConnected(true);
    } catch (err) {
      // console.log("User Closed");
    }
  };

  const Disconnect = async () => {
    web3Modal.clearCachedProvider();
    setConnected(false);
    setAddress("0x0");
  };

  return (
    connected ? (
      <button className="indicator link"  onClick={Disconnect}>
        <img src="./images/wallet.svg" loading="lazy" alt="" className="indicator-icon" width={30}/>
        <div className="indicator-desc">
          <div className="indicator-description">{address}</div>
        </div>
      </button>
      ) : (
      <button className="indicator link"  onClick={onConnect}>
        <img src="./images/wallet.svg" loading="lazy" alt="" className="indicator-icon" width={30}/>
        <div className="indicator-desc">
          <div className="indicator-description">Connect Wallet</div>
        </div>
      </button>
      )
  );
};

export default Nav;
