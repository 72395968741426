import { useLocation } from "react-router-dom";
import { MainContext, useContext } from "../../contex";
import { useState, useEffect, useRef } from 'react';
import Swal from "sweetalert2";
// import Web3 from "web3";
import Nav from '../Nav';
import Hex from '../Hex';
import Detail from "../Detail";
import copy from 'copy-text-to-clipboard';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


const Contract = require('web3-eth-contract');
const empty = '0x0000000000000000000000000000000000000000'

function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    // Remember the latest function.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  
}

const Monitor = () => {

    const { connected, address, web3, provider, token, beeBlock, blockdiff, setBlockDiff, packageId, setPackageId, seconds } = useContext(MainContext);
    
    Contract.setProvider(provider);

    const Query = useQuery();

    let addref = Query.get("address");

    const [hexagons, setHexagon] = useState([]);
    
    const [row, setRow] = useState([]);
    const [balanceOf, setBalanceOf] = useState(0);
    const [refvalue, setRefValue] = useState('');
    const [referrer, setReferrer] = useState(empty);
    const [hasref, setHasRef] = useState(empty);
    const [nonce, setNonce] = useState(0);
    const [refresh, setRefresh] = useState(0);
    const [enable, setEnable] = useState(0);
    const [userblocklimit, setUserBlockLimit] = useState(0);
    const [block, setBlock] = useState(0);
    const [energy, setEnergy] = useState(0);
    const [totalReferance, setTotalReferance] = useState(0);
    const [withdrawn, setWithdrawn] = useState(0);
    const [spendToken, setSpendToken] = useState(0);
    const [copyColor, setCopyColor] = useState('black');
    
    const [detailId, setDetailId] = useState(0);
      
    useInterval(() => {
        // Your custom logic here
        console.log(refresh);
        setRefresh(refresh + 1);
    }, 5000);

    useEffect(() => {
        const fetchData = async () => {
            if(addref != null){
                setRefValue(addref);
                setReferrer(addref);
            }
        }
        fetchData();
    }, [addref]);
      
    useEffect(() => {
        const row = hexagons.filter((x,index)=>index % 5 === 0);
        setRow(row);
        //setDetailId(0);
    },[connected, address, nonce, hexagons]);

    useEffect(() => {
        let _energy = ((parseFloat(userblocklimit) - parseFloat(blockdiff)) / seconds).toFixed(2);
        //_energy = _energy - 4;
        console.log('e:',_energy);
        _energy = (4 - _energy >= 4) ? 4 : 4 - _energy ;
        setEnergy(_energy);
        // console.log('e:',_energy);
    },[userblocklimit, blockdiff, refresh])

    // Block
    useEffect(() => {
        const fetchData = async () => {
            if(connected){
                let _block = await web3.eth.getBlockNumber();
                setBlock(_block);
                console.log('Block', _block);
            }
        }
        fetchData();
    }, [connected, address, nonce, hexagons, refresh]);

    // Package ID
    useEffect(() => {
        const fetchData = async () => {
            if(connected){
                const ABI = [{
                    "inputs": [],
                    "name": "packageId",
                    "outputs": [
                      {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                      }
                    ],
                    "stateMutability": "view",
                    "type": "function",
                    "constant": true
                  }];
      
                let _contract = new Contract(ABI, beeBlock);
                
                let _packageid = await _contract.methods.packageId().call({from:address});
                console.log('package_id',_packageid);
                setPackageId(_packageid);
            }
        }
        fetchData();
      }, [connected, address, nonce, refresh]);
    //BlockDiff
    useEffect(() => {
        const fetchData = async () => {
            if(connected){
                const ABI = [{
                    "inputs": [],
                    "name": "getBlockDiff",
                    "outputs": [
                      {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                      }
                    ],
                    "stateMutability": "view",
                    "type": "function",
                    "constant": true
                }];
      
                let _contract = new Contract(ABI, beeBlock);
                
                let _blockdiff = await _contract.methods.getBlockDiff().call({from:address});
                console.log('diff',_blockdiff);
                setBlockDiff(_blockdiff);
            }
        }
        fetchData();
      }, [connected, address, nonce, refresh]);

    //userBlockLimit
    useEffect(() => {
        const fetchData = async () => {
            if(connected){
                const ABI = [{
                    "inputs": [
                      {
                        "internalType": "address",
                        "name": "",
                        "type": "address"
                      }
                    ],
                    "name": "userBlockLimit",
                    "outputs": [
                      {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                      }
                    ],
                    "stateMutability": "view",
                    "type": "function",
                    "constant": true
                  }];
      
                let _contract = new Contract(ABI, beeBlock);
                
                let _userblocklimit = await _contract.methods.userBlockLimit(address).call({from:address});
                console.log('limit',_userblocklimit);
                setUserBlockLimit(_userblocklimit);
            }
        }
        fetchData();
      }, [connected, address, nonce, refresh]);

    // Allowance
    useEffect(() => {
        const fetchData = async () => {
            if(connected){
                const ABI = [{
                  "inputs": [
                    {
                      "internalType": "address",
                      "name": "owner",
                      "type": "address"
                    },
                    {
                      "internalType": "address",
                      "name": "spender",
                      "type": "address"
                    }
                  ],
                  "name": "allowance",
                  "outputs": [
                    {
                      "internalType": "uint256",
                      "name": "",
                      "type": "uint256"
                    }
                  ],
                  "stateMutability": "view",
                  "type": "function"
                }];
      
                let _contract = new Contract(ABI, token);
                
                let _enable = await _contract.methods.allowance(address,beeBlock).call();
                console.log(_enable);
                setEnable(web3.utils.fromWei(_enable,'ether'));
            }
        }
        fetchData();
      }, [connected, address, nonce]);
    // BalanceOf
    useEffect(() => {
        const fetchData = async () => {
            if(connected){
                const ABI = [{
                    "inputs": [
                        {
                            "internalType": "address",
                            "name": "account",
                            "type": "address"
                        }
                        ],
                        "name": "balanceOf",
                        "outputs": [
                        {
                            "internalType": "uint256",
                            "name": "",
                            "type": "uint256"
                        }
                        ],
                        "stateMutability": "view",
                        "type": "function",
                        "constant": true
                    }];
      
                let _contract = new Contract(ABI, token);
                
                let _balanceOf = await _contract.methods.balanceOf(address).call();
                setBalanceOf(parseFloat(web3.utils.fromWei(_balanceOf, 'ether')));
            }
        }
        fetchData();
    }, [connected, address, nonce, refresh]);

    // Referrer
    useEffect(() => {
        const fetchData = async () => {
            if(connected){
                const ABI = [{
                    "inputs": [
                        {
                        "internalType": "address",
                        "name": "",
                        "type": "address"
                        }
                    ],
                    "name": "referrer",
                    "outputs": [
                        {
                        "internalType": "address",
                        "name": "",
                        "type": "address"
                        }
                    ],
                    "stateMutability": "view",
                    "type": "function",
                    "constant": true
                }];
        
                let _contract = new Contract(ABI, beeBlock);
                    
                let _referrer = await _contract.methods.referrer(address).call();
                console.log(_referrer);
                setHasRef(_referrer);
            }
        }
        fetchData();
    }, [connected, address, nonce]);

    //Get User Package
    useEffect(() => {
        const fetchData = async () => {
            if(connected){
                const ABI = [{
                    "inputs": [
                      {
                        "internalType": "address",
                        "name": "_account",
                        "type": "address"
                      }
                    ],
                    "name": "getUserPackage",
                    "outputs": [
                      {
                        "internalType": "uint256[]",
                        "name": "pack",
                        "type": "uint256[]"
                      }
                    ],
                    "stateMutability": "view",
                    "type": "function"
                  }];
        
                let _contract = new Contract(ABI, beeBlock);
                    
                let _honeycombs = await _contract.methods.getUserPackage(address).call();
                console.log(_honeycombs);
                setHexagon(_honeycombs);
            }
        }
        fetchData();
    }, [connected, address, nonce, refresh]);

    //Total User Referance
    useEffect(() => {
        const fetchData = async () => {
            if(connected){
                const ABI = [{
                    "inputs": [
                      {
                        "internalType": "address",
                        "name": "",
                        "type": "address"
                      }
                    ],
                    "name": "userTotalRef",
                    "outputs": [
                      {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                      }
                    ],
                    "stateMutability": "view",
                    "type": "function",
                    "constant": true
                  }];
      
                let _contract = new Contract(ABI, beeBlock);
                
                let _totalReferance = await _contract.methods.userTotalRef(address).call();
                setTotalReferance(_totalReferance);
            }
        }
        fetchData();
    }, [connected, address, nonce]);

    //Total Withdrawn
    useEffect(() => {
        const fetchData = async () => {
            if(connected){
                const ABI = [{
                    "inputs": [
                      {
                        "internalType": "address",
                        "name": "",
                        "type": "address"
                      }
                    ],
                    "name": "withdrawn",
                    "outputs": [
                      {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                      }
                    ],
                    "stateMutability": "view",
                    "type": "function",
                    "constant": true
                  }];
      
                let _contract = new Contract(ABI, beeBlock);
                
                let _withdrawn = await _contract.methods.withdrawn(address).call();
                setWithdrawn(parseFloat(web3.utils.fromWei(_withdrawn, 'ether')));
            }
        }
        fetchData();
    }, [connected, address, nonce]);

    //Spend Token
    useEffect(() => {
        const fetchData = async () => {
            if(connected){
                const ABI = [{
                    "inputs": [
                      {
                        "internalType": "address",
                        "name": "",
                        "type": "address"
                      }
                    ],
                    "name": "spendtoken",
                    "outputs": [
                      {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                      }
                    ],
                    "stateMutability": "view",
                    "type": "function",
                    "constant": true
                  }];
      
                let _contract = new Contract(ABI, beeBlock);
                
                let _spendtoken = await _contract.methods.spendtoken(address).call();
                setSpendToken(parseFloat(web3.utils.fromWei(_spendtoken, 'ether')));
            }
        }
        fetchData();
    }, [connected, address, nonce]);

    const Enable = () => {

        const ABI = [{
          "inputs": [
            {
              "internalType": "address",
              "name": "spender",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            }
          ],
          "name": "approve",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        }];
          
        let _contract = new Contract(ABI, token);
      
        try {
            _contract.methods.approve(beeBlock,web3.utils.toWei('1000000','ether')).send({
                from: address,
                value: 0,
            }).on('transactionHash', function (hash) {
                Swal.fire({
                    title: 'Approve is Started',
                    text: 'Your Approve hash is '.concat(hash).concat('. please wait until the transaction is completed'),
                    icon: 'success',
                    allowOutsideClick: false,
                    showConfirmButton: false
                });
            })
            .on('receipt', function (receipt) {
                setNonce(nonce + 1);
                Swal.fire({
                    title: 'Approve Confirmation',
                    text: 'Confirmed Your Approve',
                    icon: 'success'
                });
            })
            .on('error', function (error, receipt) {
                Swal.fire({
                    title: 'Approve Failed',
                    icon: 'error'
                })
            });
        } catch (err) {
            Swal.fire({
                title: 'User Canceled',
                icon: 'error'
            })
            console.log(err);
        }
      }
    
    const updateReferrer = (e) => {

        let value = e.target.value;
        setRefValue(value);
      
        if(web3.utils.isAddress(value) && value !== empty){
            setReferrer(value);
        }else{
            setReferrer(address);
        }
      
    }

    const buyHoneyComb = async () => {
        const ABI = [{
            "inputs": [
              {
                "internalType": "address",
                "name": "_referred",
                "type": "address"
              }
            ],
            "name": "buyPackage",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
          }];
      
        let _contract = new Contract(ABI, beeBlock);
      
        setReferrer((referrer === '') ? empty:referrer);
      
        try {
            _contract.methods.buyPackage(referrer).send({
                from: address,
                value: 0,
            }).on('transactionHash', function (hash) {
                Swal.fire({
                    title: 'Buy Honeycomb is Started',
                    text: 'Your Honeycomb hash is '.concat(hash).concat('. please wait until the transaction is completed'),
                    icon: 'success',
                    allowOutsideClick: false,
                    showConfirmButton: false
                });
            })
            .on('receipt', function (receipt) {
                setNonce(nonce + 1);
                Swal.fire({
                    title: 'Honeycomb Confirmation',
                    text: 'Confirmed Your Honeycomb',
                    icon: 'success'
                });
            })
            .on('error', function (error, receipt) {
                Swal.fire({
                    title: 'Honeycomb Failed',
                    icon: 'error'
                })
            });
        } catch (err) {
            Swal.fire({
                title: 'User Canceled',
                icon: 'error'
            })
            console.log(err);
        }
    }

    const clickHandle = (num) => {
        console.log(num);
        setDetailId(num);
    }

    const closeDetay = () => {
        setDetailId(0);
    }

    const copyClipboard = () => {
        copy((connected) ? 'https://beeblock.cc?address='.concat(address) : '');
        setCopyColor('red');
    }

    return(
        <div className="section hero-section wf-section ptb-50 bg-opac">
                    <div className="container">
                        <div className="rows hero">
                            <div className="full-width v-group gap-24 pb-50">
                                <div>
                                    {
                                        row.map((element, index) => (
                                            index % 2 ? (
                                            <div key={index} className="hex-row even">
                                                {
                                                    hexagons.slice(index * 5, (index * 5) + 5).map((el) => {
                                                        return(
                                                            <Hex key={el} num={el} click={clickHandle} />
                                                        )
                                                    })
                                                }
                                            </div>
                                            ) : (
                                            <div key={index} className="hex-row">
                                                {
                                                    hexagons.slice(index * 5, (index * 5) + 5).map((el) => {
                                                        return(
                                                            <Hex key={el} num={el} click={clickHandle}/>
                                                        )
                                                    })
                                                }
                                            </div>
                                            )
                                        ))
                                    }
                                </div>  
                            </div>
                            <div className="full-width center">
                                <div className="box mb-50">
                                    <h1>Bee Block Stats</h1>
                                    <div className="v-group center">
                                        <Detail id={detailId} detayClose={closeDetay} />
                                        <p className="left info">TETHER : {balanceOf.toFixed(2)}</p>
                                        <p className="left info">Total Honeycomb : {packageId}</p>
                                        <p className="left info">Your Honeycomb : {hexagons.length}</p>
                                        <p className="left info">Remaining Honeycomb : { 40 - hexagons.length }</p>
                                        <p className="left info">Energy : {energy.toFixed(2)}  / 4.00 </p>
                                        <p className="left info">Your References : {totalReferance} </p>
                                        <p className="left info">Withdrawn : {withdrawn.toFixed(2)} </p>
                                        <p className="left info">Spended Money : {spendToken.toFixed(2)} </p>
                                        <p className="left info">Total Earning : {(withdrawn - spendToken).toFixed(2)} </p>
                                    </div>
                                    <div className="params-group-v">
                                        <Nav />
                                        {
                                            (parseInt(enable) <= 5) ?
                                            <button className="indicator link" onClick={Enable}>
                                                <img src="./images/bee.svg" loading="lazy" alt="" className="indicator-icon" width={30}/>
                                                <div className="indicator-desc">
                                                    <div className="indicator-description">Enable</div>
                                                </div>
                                            </button> : ''
                                        }

                                        {
                                            (connected && parseInt(enable) > 0 ) ?
                                                ((hasref === empty)) ?
                                                    <input type="text" className="referrer" value={refvalue} id="reference" aria-describedby="referenceHelp" placeholder="Reference Address" onChange={updateReferrer} /> : ''
                                                : ''
                                        }

                                        {
                                            (connected && parseInt(enable) > 0 ) ?
                                                ((hasref !== empty)) ?
                                                <button className="indicator link">
                                                    <img src="./images/referrer.svg" loading="lazy" alt="" className="indicator-icon" width={30}/>
                                                    <div className="indicator-desc">
                                                        <div className="indicator-description">{hasref}</div>
                                                    </div>
                                                </button> : ''
                                                : ''
                                        }
                                        {
                                            (connected && parseInt(enable) > 0 ) ?
                                                ((hasref === empty)) ?
                                                <button className="indicator link" onClick={copyClipboard}>
                                                    <svg height="24" viewBox="0 0 24 24" width="24" fill={copyColor} xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M14 8H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2V10c0-1.103-.897-2-2-2z"/>
                                                        <path d="M20 2H10a2 2 0 0 0-2 2v2h8a2 2 0 0 1 2 2v8h2a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z"/>
                                                    </svg>
                                                    <div className="indicator-desc">
                                                        <div className="indicator-description">Copy Your Refeference Link</div>
                                                    </div>
                                                </button> : ''
                                                : ''
                                        }
                                        
                                        {
                                            (connected && enable > 0) ?
                                                <button className="indicator link" onClick={buyHoneyComb} disabled={(energy >= 1 ) ? false:true}>
                                                    <img src="./images/buy.svg" loading="lazy" alt="" className="indicator-icon" width={30}/>
                                                    <div className="indicator-desc">
                                                        <div className="indicator-description">Buy Honeycomb</div>
                                                    </div>
                                                </button>
                                            :'' 
                                        }
                                        
                                        {/* <button className="indicator link">
                                            <img src="./images/honey.svg" loading="lazy" alt="" className="indicator-icon" width={30}/>
                                            <div className="indicator-desc">
                                                <div className="indicator-description">Withdraw Level 1</div>
                                            </div>
                                        </button>
                                        <button className="indicator link">
                                            <img src="./images/honey.svg" loading="lazy" alt="" className="indicator-icon" width={30}/>
                                            <div className="indicator-desc">
                                                <div className="indicator-description">Withdraw Level 2</div>
                                            </div>
                                        </button>
                                        <button className="indicator link">
                                            <img src="./images/honey.svg" loading="lazy" alt="" className="indicator-icon" width={30}/>
                                            <div className="indicator-desc">
                                                <div className="indicator-description">Withdraw Level 3</div>
                                            </div>
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    )
}

export default Monitor;